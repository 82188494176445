import './App.css';
import React, { useState, useEffect } from 'react';
import qs from 'qs'
declare var ZoomMtg

ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

function App(history) {

  const [state, setState] = useState({
    name: "",
    mobile: "",
    modal: false,
    errorMessage: "",
  })

  useEffect(() => {
    console.log('Start the meeting here');
    console.log(window.location.href)
    let name = qs.parse(window.location.href, { ignoreQueryPrefix: true }).name
    let mobile = qs.parse(window.location.href, { ignoreQueryPrefix: true }).mobile
    // let name = "pubudu";
    // let mobile = "12231222312";
    console.log(name, mobile)
    autoStart(name);
  }, []);

  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  // var signatureEndpoint = 'https://talless-signature-endpoint.herokuapp.com'
  var signatureEndpoint = 'https://tallees-live-stream.herokuapp.com/'
  // var apiKey = 'Smoiv25XQNiFHe18dvkt7g'
  var apiKey = 'Tjlif8m9SD6vfuL5O_fYug'
  var meetingNumber = '96071217067'
  var role = 0
  var leaveUrl = 'http://tallees.encyte.tech'
  var userName = state.name
  var userEmail = 'events@talleess.com'
  var passWord = '378987'


  function autoStart(username) {
    // e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature, username)
      }).catch(error => {
        console.error(error)
      })
  }

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature)
      }).catch(error => {
        console.error(error)
      })
  }

  function startMeeting(signature, username) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      // isShowJoiningErrorDialog: false,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: username,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success)
            // changeStyles()
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  function changeStyles() {
    document.getElementsByClassName('meeting-app').style.width = '400px'
    document.getElementsByClassName('meeting-app').style.height = '400px'
  }

  return (
    <div className="App">
      <main>
        {/* <h4 style={{ marginTop: '10%' }}>You will be redirected to the webinar soon. If you are not redirected, please click "Join Meeting"  below.</h4> */}

        {/* <button onClick={getSignature}>Start Webinar</button> */}
      </main>

    </div>
  );
}

export default App;
